* {
  outline: 0; }

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  background: #ffffff;
  font-size: 2.2222222222vw;
  font-family: "Oswald", sans-serif; }
  @media only screen and (min-width: 769px) {
    html,
    body {
      font-size: 1vw; } }

h1,
h2,
h3,
h4,
h5 {
  margin: 0; }

@media only screen and (min-width: 769px) {
  .content-block:after {
    content: '';
    display: block;
    width: 0.0625rem;
    height: 100%;
    background: #9f9e9f;
    opacity: .4;
    position: absolute;
    top: 0;
    right: 6.1875rem;
    z-index: 50; } }

#main {
  width: 100%;
  min-height: 100%;
  position: relative;
  background: #040404; }

.nav {
  background: #ed2227;
  width: 45rem;
  height: 9.5625rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000; }
  @media only screen and (min-width: 769px) {
    .nav {
      width: 6.25rem;
      height: 50rem; } }
  .nav__logo {
    width: 26.125rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media only screen and (min-width: 769px) {
      .nav__logo {
        width: 3.3125rem;
        top: 1.6875rem;
        transform: translate(-50%, 0); } }
  .nav__link {
    color: #ffffff;
    font-size: 0.8125rem;
    text-decoration: none;
    text-transform: uppercase;
    position: absolute;
    width: 43.75rem;
    height: 6.25rem;
    line-height: 6.25rem;
    text-align: center;
    border-right: 0.0625rem solid #ffffff;
    transform: rotate(-90deg) translate(-25rem, -18.75rem); }
    @media only screen and (max-width: 1024px) {
      .nav__link {
        display: none; } }

.hero {
  position: relative;
  background-image: url(../images/mobile/bg-hero.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  padding-top: 9.6875rem;
  width: 100%;
  height: 52.3125rem; }
  @media only screen and (min-width: 769px) {
    .hero {
      padding-top: 0;
      height: 50rem;
      background-image: url(../images/desktop/bg-hero.png);
      background-position: top;
      z-index: 100; }
      .hero:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        background: #9f9e9f;
        width: 6.25rem;
        height: 8.625rem; } }
  .hero__logo {
    font-size: 0;
    width: 26.8125rem;
    height: 18.625rem;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 28.75rem;
    left: 10.625rem; }
    @media only screen and (min-width: 769px) {
      .hero__logo {
        top: 18.3125rem;
        left: 50rem; } }
  .hero__title {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-size: 3.75rem;
    color: #ed2227;
    width: 23.875rem;
    line-height: 1.17;
    position: absolute;
    top: 12.625rem;
    left: 4.4375rem; }
    @media only screen and (min-width: 769px) {
      .hero__title {
        top: 18.3125rem;
        left: 13.1875rem; } }
  .hero__more {
    box-sizing: border-box;
    width: 27.9375rem;
    height: 8.625rem;
    line-height: 8.625rem;
    position: absolute;
    background: #9f9e9f;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    text-align: right;
    font-size: 1.625rem;
    font-weight: 300;
    padding-right: 7.9375rem;
    bottom: 0;
    right: 4.9375rem; }
    @media only screen and (min-width: 769px) {
      .hero__more {
        width: 25.8125rem;
        font-size: 0.8125rem;
        padding-right: 9.9375rem;
        right: auto;
        left: 6.25rem; } }
    .hero__more:before {
      content: '';
      display: block;
      width: 1.8125rem;
      height: 2.375rem;
      background-image: url(../images/shared/arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 3rem;
      left: 3.625rem; }
      @media only screen and (min-width: 769px) {
        .hero__more:before {
          width: 0.75rem;
          height: 1rem;
          top: 3.8125rem;
          left: 10rem; } }

.about {
  width: 100%;
  background: #040404;
  color: #ffffff;
  position: relative;
  padding-bottom: 5.625rem; }
  @media only screen and (min-width: 769px) {
    .about {
      height: 47.5rem;
      background-image: url(../images/desktop/bg-about.png);
      background-size: contain;
      margin-top: -8.625rem;
      padding-bottom: 0; } }
  .about__title {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-size: 1.625rem;
    font-weight: 300;
    padding: 6.6875rem 4.125rem 0 12.0625rem; }
    @media only screen and (min-width: 769px) {
      .about__title {
        padding: 0;
        position: absolute;
        transform: rotate(-90deg);
        font-size: 0.875rem;
        top: 19.5625rem;
        left: 10.9375rem; } }
  .about__lead {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-size: 2.25rem;
    line-height: 1.33;
    padding: 3.8125rem 4.125rem 0 12.0625rem; }
    @media only screen and (min-width: 769px) {
      .about__lead {
        padding: 17.25rem 37rem 0 32.0625rem; } }
  .about__text {
    font-family: "Roboto", sans-serif;
    font-size: 1.875rem;
    line-height: 1.57;
    padding: 2.75rem 4.125rem 0 12.0625rem; }
    @media only screen and (min-width: 769px) {
      .about__text {
        font-size: 0.9375rem;
        line-height: 2;
        padding: 4rem 42.625rem 0 32.0625rem; }
        .edge .about__text,
        .ie11 .about__text {
          padding-right: 40.75rem; } }

.brands {
  background: #ffffff;
  position: relative;
  overflow: auto; }
  .brands__title {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    color: #626262;
    font-size: 1.78125rem;
    font-weight: 300;
    padding: 6.25rem 0 6.25rem 5.0625rem;
    border-bottom: 0.0625rem solid rgba(4, 4, 4, 0.4); }
    @media only screen and (min-width: 769px) {
      .brands__title {
        font-size: 0.875rem;
        padding: 7.8125rem 0 3.625rem 13.1875rem;
        margin-right: 6.25rem;
        border-bottom: 0.0625rem solid rgba(159, 158, 159, 0.4); } }
  .brands__list {
    list-style: none;
    font-size: 0;
    padding: 0;
    width: 100%; }
    @media only screen and (min-width: 769px) {
      .brands__list {
        width: 78.125rem;
        margin: -1.25rem auto 1.25rem; } }
    .brands__list--item {
      width: 13.75rem;
      height: 3.75rem;
      line-height: 6.25rem;
      display: none;
      text-align: center;
      margin: 4.375rem; }
      @media only screen and (min-width: 769px) {
        .brands__list--item {
          display: inline-block;
          width: 16%;
          margin: 3.4375rem 0; } }
      .brands__list--item.open {
        display: inline-block; }
      .brands__list--item img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
        transform: scale(1.8); }
        @media only screen and (min-width: 769px) {
          .brands__list--item img {
            transform: scale(1.1); } }
        .brands__list--item img.amnet {
          width: 6.25rem; }
        .brands__list--item img.cosin {
          width: 6.25rem; }
        .brands__list--item img.dentsu {
          width: 5rem; }
        .brands__list--item img.iprospect {
          width: 6.25rem; }
        .brands__list--item img.isobar {
          width: 4.375rem; }
        .brands__list--item img.lov {
          width: 3.125rem; }
        .brands__list--item img.mgb {
          width: 6.875rem; }
        .brands__list--item img.mktg {
          width: 3.125rem; }
        .brands__list--item img.nbs {
          width: 3.125rem; }
        .brands__list--item img.pontomobi {
          width: 5.625rem; }
        .brands__list--item img.posterscope {
          width: 6.875rem; }
        .brands__list--item img.storylab {
          width: 6.25rem; }
  .brands__open-button {
    border: 0.1875rem solid #b6b6b6;
    background: #ffffff;
    color: #b6b6b6;
    display: block;
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 1.625rem;
    text-transform: uppercase;
    padding: 2.4375rem 8.625rem;
    margin: 3.125rem auto 6.25rem; }
    @media only screen and (min-width: 769px) {
      .brands__open-button {
        display: none; } }

.how {
  width: 100%;
  background: #040404;
  color: #ffffff;
  position: relative; }
  .how:before {
    content: '';
    display: block;
    position: absolute;
    background: #ed2227;
    z-index: 10;
    width: 100%;
    height: 40.1875rem;
    top: 0;
    left: 0; }
    @media only screen and (min-width: 769px) {
      .how:before {
        height: 39.25rem; } }
  .how__title {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-size: 1.625rem;
    font-weight: 300;
    transform: rotate(-90deg);
    position: absolute;
    top: 10.625rem;
    left: 1rem;
    z-index: 20; }
    @media only screen and (min-width: 769px) {
      .how__title {
        font-size: 0.875rem;
        top: 8.4375rem;
        left: 11.25rem; } }
  .how__lead {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-size: 3.75rem;
    line-height: 1.17;
    padding: 26.875rem 24.4375rem 0 5.0625rem;
    position: relative;
    z-index: 30; }
    @media only screen and (min-width: 769px) {
      .how__lead {
        padding: 21.25rem 69.25rem 0 14.5rem; } }
  .how__image {
    display: block;
    position: absolute;
    z-index: 10;
    width: 32.75rem;
    height: 38.4375rem;
    top: 6.875rem;
    right: 0; }
    @media only screen and (min-width: 769px) {
      .how__image {
        width: 61.875rem;
        height: 42.125rem;
        top: 6.25rem;
        right: 6.25rem; } }
  .how__text {
    font-family: "Roboto", sans-serif;
    font-size: 1.875rem;
    line-height: 1.57;
    padding: 13.75rem 4.125rem 2.5rem 12.0625rem;
    margin-bottom: 0; }
    @media only screen and (min-width: 769px) {
      .how__text {
        font-size: 0.9375rem;
        line-height: 2;
        padding: 22rem 42rem 2.5rem 32.25rem; } }

.steps {
  background: #040404;
  color: #ffffff;
  position: relative;
  overflow: auto; }
  .steps__title {
    font-family: "Oswald", sans-serif;
    font-size: 3.75rem;
    text-transform: uppercase;
    padding: 1.25rem 10.125rem 5.5rem 12.25rem; }
    @media only screen and (min-width: 769px) {
      .steps__title {
        padding: 6.25rem 0 5.75rem;
        text-align: center; } }
  .steps__list {
    width: 34.875rem;
    margin: 0 auto;
    padding: 0;
    font-size: 0; }
    @media only screen and (min-width: 769px) {
      .steps__list {
        width: 73.6875rem;
        margin-bottom: 6.875rem; } }
    .steps__list--item {
      list-style: none;
      width: 100%;
      height: 14rem;
      font-family: "Oswald", sans-serif;
      font-size: 2.125rem;
      text-transform: uppercase;
      text-align: center;
      padding: 5.3125rem 0;
      box-sizing: border-box;
      background: #ed2227;
      box-shadow: 0 0.1875rem 7.125rem 0 rgba(0, 0, 0, 0.1); }
      @media only screen and (min-width: 769px) {
        .steps__list--item {
          width: 24.5rem;
          height: 9.8125rem;
          display: inline-block;
          font-size: 1.5rem;
          padding: 3.75rem 0;
          box-shadow: 0 0.125rem 5rem 0 rgba(0, 0, 0, 0.1); }
          .steps__list--item:nth-child(1n+2) {
            border-left: 0.0625rem solid rgba(255, 255, 255, 0.4); } }

.join {
  background: #040404;
  color: #ffffff;
  overflow: auto;
  position: relative; }
  @media only screen and (min-width: 769px) {
    .join:before {
      content: '';
      position: absolute;
      width: 93.75rem;
      height: 0.0625rem;
      background: #9f9e9f;
      opacity: .4; }
      .edge .join:before,
      .ie11 .join:before {
        width: calc(93.75rem - 0.875rem); } }
  .join__title {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    font-size: 3.75rem;
    line-height: 1.17;
    padding: 5rem 10.125rem 0 12.25rem; }
    @media only screen and (min-width: 769px) {
      .join__title {
        padding: 6.25rem 41.5rem 0 32.25rem; } }
  .join__link {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-size: 1.625rem;
    font-weight: 300;
    color: #b6b6b6;
    border: 0.1875rem solid #b6b6b6;
    display: block;
    width: 27.9375rem;
    height: 8.125rem;
    line-height: 8.125rem;
    margin: 5.5rem 0 6.875rem 12.25rem; }
    @media only screen and (min-width: 769px) {
      .join__link {
        font-size: 0.8125rem;
        width: 17.1875rem;
        height: 4.75rem;
        line-height: 4.75rem;
        color: #ffffff;
        border: 0.1875rem solid #ffffff;
        margin: 4.8125rem 0 6.5rem 32.25rem; } }

.footer {
  background: #040404;
  position: relative;
  overflow: auto; }
  .footer:before {
    content: '';
    display: block;
    width: 100%;
    height: 0.0625rem;
    background: #9f9e9f;
    opacity: .4; }
    @media only screen and (min-width: 769px) {
      .footer:before {
        width: 93.75rem; }
        .edge .footer:before,
        .ie11 .footer:before {
          width: calc(93.75rem - 0.875rem); } }
  .footer__list {
    padding: 0;
    text-align: center; }
    @media only screen and (min-width: 769px) {
      .footer__list {
        text-align: left;
        padding-left: 27.875rem; } }
    .footer__list--item {
      list-style: none;
      display: inline-block;
      margin: 1.875rem;
      margin-bottom: 3.125rem;
      width: 36.25rem;
      box-sizing: border-box; }
      .footer__list--item.facebook, .footer__list--item.linkedin, .footer__list--item.instagram {
        width: 2.375rem;
        margin-bottom: 5.625rem; }
      @media only screen and (min-width: 769px) {
        .footer__list--item {
          width: auto;
          margin: 2.5rem 2.5rem 3.5rem 4.375rem; }
          .footer__list--item.facebook, .footer__list--item.linkedin, .footer__list--item.instagram {
            width: auto;
            margin-right: 4.0625rem; } }
    .footer__list--link {
      font-family: "Roboto", sans-serif;
      font-size: 1.875rem;
      color: #ffffff;
      text-decoration: none; }
      @media only screen and (min-width: 769px) {
        .footer__list--link {
          font-size: 0.9375rem;
          color: #b6b6b6; } }
      .footer__list--link.facebook, .footer__list--link.linkedin, .footer__list--link.instagram {
        font-size: 0;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        background-size: auto 2.375rem;
        background-repeat: no-repeat; }
        @media only screen and (min-width: 769px) {
          .footer__list--link.facebook, .footer__list--link.linkedin, .footer__list--link.instagram {
            font-size: 0.9375rem;
            background-size: auto 1.0625rem;
            padding-left: 1.875rem;
            padding-top: 0.125rem; } }
      .footer__list--link.facebook {
        background-image: url(../images/shared/facebook.png); }
      .footer__list--link.linkedin {
        background-image: url(../images/shared/linkedin.png); }
      .footer__list--link.instagram {
        background-image: url(../images/shared/instagram.png); }
